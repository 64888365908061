import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00446f',
    },
    secondary: {
      main: '#009cb4',
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#ffffff',
    },
    error: {
      main: '#d32f2f',
    },
    info: {
      main: '#ededed',
    },
    success: {
      main: '#446f00',
    },
  },
})

export default theme
